.question {
  margin: auto;
  max-width: 1190px;
  text-align: center;
  margin-top: 40px;
  position: relative;
  z-index: 6;
  animation: fadeIn 0.4s forwards;
  animation-delay: 0.1s;
  opacity: 0;
}

.question_submit {
  padding: 0;
  max-width: 470px;
  margin: auto;
}

.question_subhead {
  color: #AEA3B8;
  font-size: 28px;
  font-weight: 800;
  font-family: 'AvenirNext';
  letter-spacing: -0.5px;
  text-align: center;
  opacity: 0.4;
  display: block;
  line-height: 110%;
  margin-bottom: 20px;
}

.question_subhead--smaller {
  font-size: 20px;
}

.question_head {
  color: rgb(104, 63, 145);
  font-size: 32px;
  font-weight: 800;
  font-family: 'AvenirNext';
  letter-spacing: -1.8px;
  text-align: center;
  display: block;
  margin-bottom: 24px;
  line-height: 110%;
}

.confettis {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  z-index: 2;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 770px) {
  .question_head {
    font-size: 60px;
    letter-spacing: -3px;
  }

  .question_head--smaller {
    font-size: 56px;
  }
}