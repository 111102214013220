.businessselector_nomatch {
  margin: auto;
  display: block;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  padding: 22px 0;
  font-size: 18px;
  color: #683f91;
}
.businessselector {
  border-radius: 10px;
  background: white;
  border: 1px solid rgba(102, 75, 129, 0.2);
  max-width: 660px;
  margin: 5px auto;
  padding: 38px;
  font-weight: 600;
  transition: 0.3s;
  cursor: pointer;
  color: #683f91;
}
.businessselector:hover {
  color: white;
  background: #825cbb
}
.businessselector_head {
  font-size: 24px;
  display: block;
  margin: auto;
  line-height: 170%;
}
.businessselector_content {
  color: rgba(40, 0, 75, 0.48);
}
