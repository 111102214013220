.helper-wrapper {
  background: black;
  opacity: 0.3;
  width:100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}
.helper {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 21;
  transform: translate(-50%,-50%);
  background: white;
  border-radius: 0.625rem;  
  width: calc(100% - 40px);
  max-width: 600px;
}
.helper__content {
  position:relative;
  padding: 40px;
  width: 100%;
  white-space: pre-line;
  font-size: 16px;
  font-family: 'AvenirNext';
}
.helper__close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  cursor: pointer;
  opacity: 0.3;
} 
.helper__head {
  color: rgb(104, 63, 145);
  font-size: 32px;
  font-weight: 800;
  font-family: 'AvenirNext';
  letter-spacing: -1.8px;
  display: block;
  margin-bottom: 24px;
}