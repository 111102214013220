.form {
  margin: 0 auto;
  max-width: 328px;
  padding-bottom: 40px;
}
.form_submit {
  text-align: center;
  padding-top: 14px;
}
.form_submit_btn {
  background: #905fda;
  border-radius: 25px;
  height: 48px;
  border: 0;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 25px;
  min-width: 160px;
  cursor: pointer;
  text-decoration: none;
  min-width: 100%;
  transition: 0.3s;
}
.form_submit_btn--lower {
  text-transform: initial;
}
.form_submit_btn {
  text-decoration: none;
}
a .form_submit_btn {
  text-decoration: none;
}
.form_submit_btn:focus {
  outline: 0;
}

.form_submit_btn:hover {
  background: #997ad3;
}

.form_submit_btn:active {
  background: #b39bde;
}

.form_submit_btn--alt {
  background: none;
  border: 1px solid rgb(109, 74, 211);
  color: rgb(109, 74, 211);
}
.form_consent {
  color: rgba(40, 0, 75, 0.38);
  font-size: 12px;
  font-weight: normal;
  height: 60px;
  letter-spacing: 0.03px;
  display: block;
  padding-top: 30px;
  max-width: 440px;
}
.form_error {
  text-align: center;
  display: block;
  padding: 5px 0;
  color: rgb(241, 130, 141);
}
@media screen and (min-width: 770px) {
  .form {
    max-width: 428px;
  }
  .form_submit_btn {
    min-width: 160px;
  }
}
@media screen and (min-width: 1008px) {
  .form {
    max-width: initial;
  }
  .form_submit,
  .form_error {
    text-align: left;
  }
}
