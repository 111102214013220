.thankyou {
  margin: 0 auto 0 auto;
  padding: 42px 25px 0 25px;
  width: 100%;
  max-width: 700px;
  background: url('./paper.png') no-repeat;
  background-position: bottom center;
  background-size: cover;
  min-height: 100vh;
}

.advantages {
  display: none;
  flex-direction: row;
  justify-content: center;
  padding: 32px 0;
  flex-wrap: wrap;
}

.advantages__container {
  width: 100%;
}

.advantages__header {
  font-family: 'AvenirNext';
  font-weight: bold;
  color: #4c1d95;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -2.4px;
  margin: 24px 0;
}

.list-items {
  max-width: 22rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-items> :first-child {
  margin-top: 3rem;
}

.list-items__header {
  font-weight: 700;
  font-family: 'Avenir Next';
  font-size: 16px;
  line-height: 100%;
  color: #4c1d95;
  margin-bottom: 2px;
}

.list-items__header:before {
  content: '\2713';
  letter-spacing: 3px;
  color: #4c1d95;
}

.thanks_content {
  padding: 25px;
}

.thankyou-p {
  text-align: center;
  padding: 0 0 13px 0;
  margin: auto;
  color: #664b81;
}

.thankyou-p--alignleft {
  text-align: left;
  margin: 0;
  padding: 0 0 20px 0;
}

.thankyou-p--larger {
  max-width: 35rem;
}

.stores {
  text-align: center;
  margin-top: 1.5rem;
}

.stores_store {
  height: 34px;
  margin: 0 10px;
}

.stores_store:hover {
  transform: scale(1.01) translate3d(0, -1px, 0);
}

.thankyou-emailaccount {
  text-align: center;
}

.thankyou-emailaccount_email {
  color: #28004b;
  font-weight: 700;
  font-size: 17px;
  background: rgba(109, 74, 211, 0.12);
  border-radius: 25px;
  padding: 10px 40px;
  display: inline-block;
  word-break: break-all;
}

.thankyou-desktop {
  display: none;
}

.thankyou-smssent {
  color: #61a961;
  font-weight: 600;
  font-size: 16px;
}

.thankyou-download-button {
  margin-top: 60px;
  text-align: center;
}

.thankyou-phone {
  max-width: 320px;
  margin: auto;
  display: inline-block;
}

@media screen and (min-height: 720px) {
  .thankyou-phone {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 1008px) {
  .thankyou {
    margin-top: 0;
    padding: 0 56px;
    max-width: 1120px;
  }

  .advantages__container {
    width: 50%;
  }
}

@media screen and (min-width: 770px) {
  .thankyou {
    background: none;
  }

  .advantages {
    display: flex;
  }

  .thankyou-emailaccount {
    text-align: left;
  }

  .thankyou-mobile {
    display: none;
  }

  .thanks_content {
    background: white;
    box-shadow: 0px 2px 100px 0px rgba(40, 0, 75, 0.3);
    width: 600px;
    margin: 50px auto;
    padding: 60px 70px;
    border-radius: 8px;
    position: relative;
  }

  .stores {
    text-align: left;
  }

  .stores_store:first-child {
    margin-left: 0;
  }

  .thankyou-desktop {
    display: block;
  }

  .thankyou-p {
    font-size: 16px;
    text-align: left
  }

  .thankyou-p--smaller {
    font-size: 14px;
  }

  .form_submit_btn--secondary {
    width: unset;
  }
}

.form_submit_btn--secondary {
  border: 1px solid #8059c8;
  color: #4c1d95;
  border-radius: 25px;
  min-width: 12rem;
  height: 48px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  padding: 14px 25px;
  cursor: pointer;
  transition: 0.3s;
  display: inline-block;
}

.form_submit_btn--secondary:hover {
  background: #8059c8;
  color: white;
}

.form_submit_btn--secondary:active {
  background: #a68bd9;
  color: white;
}

.thanks_sensmslink_Form {
  display: flex;
}

.sendsmslink_Button {
  transform: translateX(-3rem);
}