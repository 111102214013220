.rightpane {
}
.right_pane_logo {
  text-align: center;
  padding-top: 71px;
}
.testimonials {
  padding-top: 60px;
}
.rightpane_app {
  max-width: 443px;
  margin: auto;
  display: block;
}
.testimonial {
  background: white;
  width: 347px;
  margin: 0 auto 30px auto;
  border-radius: 25px;
  padding: 40px;
}
.testimonial_head {
  display: flex;
}
.testimonial_pic {
  width: 50px;
  border-radius: 50%;
  margin-right: 20px;
}
.testimonial_name {
  font-family: 'AvenirNext';
  font-size: 16px;
}
.testimonial_platform {
  opacity: 0.38;
  margin-top: 5px;
  font-family: 'AvenirNext';
  font-size: 14px;
  margin-left: 5px
}
.testimonial_platform a {
  color:inherit;
}
.testimonial_stars {
  display: block;
  margin-top: 5px
}
.testimonial_content {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 25px;
  color: rgb(132, 114, 149);
}