.goback {
  color: #867694;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  left: 60px;
  top: 35px;
}

.goback__icon {
  margin-right: 8px;
  vertical-align: sub;
}