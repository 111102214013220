.tabs {
  display: none;
}
.tabs_tab {
  color: #683f91;
  font-weight: 600;
  font-size: 16px;
  padding: 25px 0 30px 0;
  margin-right: 30px;
  opacity: 0.2;
}
.tabs_tab--enabled {
  opacity: 1;
  color: #683f91;
}
.tabs_tab--active {
  border-bottom: 3px solid #683f91;
}
.tabs_tab_icon {
  margin-right: 9px;
  vertical-align: baseline;
  width: 10px;
}
@media screen and (min-width: 1008px) {
  .tabs {
    display: block;
    border-bottom: 1px solid rgba(102, 75, 129, 0.35);
    margin-bottom: 30px;
    display: flex;
  }
}