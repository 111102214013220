.onboarding {
  width: 100%;
  min-height: 100vh;
  background: #f8f6f9;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .onboarding {
    padding: 0 30px;
  }
}