.badges {
  display: none;
}

.badges img {
  max-height: 48px;
  margin-right: 13px;
}

@media screen and (min-width: 1008px) {
  .badges {
    display: block;
  }
}