/* AVENIR FONT */


@font-face {
  font-family: 'AvenirNext';
  src: url('res/fonts/avenir/AvenirNext-Bold.ttf') format('truetype'),
    url('res/fonts/avenir/AvenirNext-Bold.eot') format('embedded-opentype'),
    url('res/fonts/avenir/AvenirNext-Bold.woff') format('woff'),
    url('res/fonts/avenir/AvenirNext-Bold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+000-5FF; /* Latin glyphs */
}
@font-face {
  font-family: 'AvenirNext';
  src: url('res/fonts/avenir/AvenirNext-Regular.eot')
      format('embedded-opentype'),
    url('res/fonts/avenir/AvenirNext-Regular.ttf') format('truetype'),
    url('res/fonts/avenir/AvenirNext-Regular.woff') format('woff'),
    url('res/fonts/avenir/AvenirNext-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+000-5FF; /* Latin glyphs */
}

/* ASSISTANT FONT */
@font-face {
  font-family: 'Assistant';
  src: url('res/fonts/assistant/Assistant-SemiBold.ttf') format('truetype'),
    url('res/fonts/assistant/Assistant-SemiBold.woff') format('woff'),
    url('res/fonts/assistant/Assistant-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: 'Assistantuu';
  src: url('res/fonts/assistant/Assistant-Regular.eot')
      format('embedded-opentype'),
    url('res/fonts/assistant/Assistant-Regular.ttf') format('truetype'),
    url('res/fonts/assistant/Assistant-Regular.woff') format('woff'),
    url('res/fonts/assistant/Assistant-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+000-5FF; /* Latin glyphs */
}

body,
html {
  font-family: 'Assistant';
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  scroll-behavior: smooth;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto 400px;
  font-size: 14px;
  overflow-x: hidden;
}
*, ::after, ::before {
  box-sizing: border-box;
}
.content {
  margin: 0 25px;
  position: relative;
}
.content_right{
  display: none;
}
@media screen and (min-width: 1008px) {
  .content {
    margin: auto;
    max-width: 1200px;
    padding-left: 25px;
  }
  .content_left{
    margin-right: 560px;
  }
  .content_right{
    display: block;
    width: 504px;
    min-height: 100vh;
    height: 100%;
    position: absolute;
    right:0;
    top: 0;
    background: #f9f8fd;
  }
  .form_submit {
    text-align: left;
  }
  .content_right_sticky{
    position: sticky;
    top: 0;
  }
}
@media screen and (min-width: 1200px) {
  .content_right_wrapper {
    position: fixed;
    background: #f9f8fd;
    height: 100%;
    width: calc((100vw - 1175px)/2);
    top: 0;
    right: 0%;
  }
}