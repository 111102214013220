.headline {
  color: #683f91;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -2.26px;
  font-weight: 800;
  font-family: 'AvenirNext';
  margin: 0;
  text-align: center;
}
.headline--left {
  text-align: left;
}
@media screen and (min-width: 520px) {
  .headline {
    font-size: 48px;
  }
}
@media screen and (min-width: 1008px) {
  .headline {
    text-align: left;
    padding-top: 80px;
  }
  .headline--center {
    text-align: center;
  }
  .headline--noPadding {
    padding: 0;
  }
}
@media screen and (min-width: 1200px) {
  .headline {
    font-size: 70px;
    line-height: 65px;
    letter-spacing: -3.28px;
  }
}
