.subheadline {
  color: rgb(40, 0, 75);
  font-size: 16px;
  margin: 0;
  text-align: center;
  font-weight: 600;
  opacity: 0.6;
}
@media screen and (min-width:520px) {
  .subheadline {
    font-size: 20px;
  }
}
@media screen and (min-width:1008px) {
  .subheadline {
    text-align: left;
  }
}
@media screen and (min-width:1200px) {
  .subheadline {
    font-size: 24px;
    padding-bottom: 20px;
  }
}