.checkmarks {
  padding: 10px 0 25px 0;
}
.checkmarks_key {
  display: block;
  text-align: center;
  color: #2e005761;
  font-weight: 600;
  letter-spacing: -0.01px;
  line-height: 18px;
}
.checkmarks_key_desktop {
  display: none;
}
.checkmarks_tick { 
  margin-right: 10px;
  height: 11px;
}
@media screen and (min-width:520px) {
  .checkmarks {
    font-size: 16px;
    display: flex;
    justify-content: center;
  }
  .checkmarks_key {
    padding: 0 10px;
  }
}
@media screen and (min-width:770px) {
  .checkmarks_key {
    padding: 0 30px 0 0;
  }
  .checkmarks_key:last-child {
    padding: 0;
  }
}
@media screen and (min-width:1008px) {
  .checkmarks {
    justify-content: left;
  }
}
@media screen and (min-width:1200px) {
  .checkmarks_key_desktop {
    display: block;
  }
}