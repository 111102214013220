.progressbar {
  margin: auto;
  width: 1190px;
  max-width: 100%;
  display: flex;
  padding: 80px 0 42px 0;
}

.progressbar_step {
  flex-grow: 1;
  margin: 0 10px;
  text-align: center;
  position: relative;
}

.progressbar_bar {
  border-radius: 50px;
  height: 12px;
  background: rgb(102, 75, 129);
  opacity: 0.11;
  transition: 0.2s;
  width: 100%;
  display: block;
}

.progressbar_bar--active {
  opacity: 1;
  cursor: pointer;
  background: #905fda;
}

.progressbar_label {
  display: inline-block;
  background: rgb(102, 75, 129, 0.11);
  color: rgba(118, 86, 150, 0.5);
  min-width: 100%;
  padding: 5px;
  border-radius: 10px;
  margin-top: 10px;
  position: relative;
  font-size: 14px;
  opacity: 0;
  transition: 0.4s;
  transition-delay: 0.15s;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.progressbar_label::after {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgb(102, 75, 129, 0.11);
  left: 50%;
  top: 0;
  content: ' ';
  position: absolute;
  transform: translate(-50%, -100%);
}

.progressbar_label--active {
  cursor: pointer;
  color: white;
  background: #905fda;
}

.progressbar_label--active::after {
  border-bottom: 5px solid #905fda;
}

.progressbar_step:hover .progressbar_label {
  opacity: 1;
}

@media screen and (min-width: 860px) {
  .progressbar_step:hover .progressbar_label {
    opacity: 1;
  }
}