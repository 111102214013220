.question_input {
  background: rgb(255, 255, 255);
  border-radius: 10px;
  border: 1px solid rgba(102, 75, 129, 0.2);
  height: 48px;
  width: 100%;
  color: rgba(40, 0, 75, 0.87);
  max-width: 400px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
}

.field_input--select {
  background: url("./arrow-down.svg"), #fff;
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.question_input:focus {
  outline: 0;
  border: 2px solid #905fda;
  border-radius: 10px;
}

.field_input--error {
  border: 1px solid rgb(241, 130, 141);
}

.question_input::placeholder {
  color: rgba(40, 0, 75, 0.2);
}

.question_input:-ms-input-placeholder {
  color: rgba(40, 0, 75, 0.12);
}

.question_input::-ms-input-placeholder {
  color: rgba(40, 0, 75, 0.12);
}

.field_error {
  color: rgb(241, 130, 141);
  display: block;
  padding: 3px 0 5px 0;
  min-height: 27.5;
  display: flex;
  flex-direction: column;
}

.field_error span {
  display: block;
}

.questionselect {
  display: flex;
  justify-content: center;
}

.questionselect_option {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 28px;
  margin: 0 5px;
  border-radius: 10px;
  min-width: 120px;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid rgba(102, 75, 129, 0.2);
  color: rgb(104, 63, 145);
  font-weight: 600;
  max-width: 340px;
  flex-grow: 1;
  transition: 0.3s;
  flex: 1;
}

.questionselect_option:hover {
  color: white;
  background: #825cbb;
}

.questionselect_option--smaller {
  min-width: initial;
  max-width: 120px;
}

.question_select_option_name {
  font-size: 20px;
  letter-spacing: -0.03px;
}

.question_select_option_desc {
  opacity: 0.4;
  font-size: 16px;
  line-height: 100%;
  margin-top: 5px;
  display: inline-block;
}

.questionselect_option:first-child {
  margin-left: 0;
}

.questionselect_option:last-child {
  margin-right: 0;
}

.questionselect_option--selected {
  background: #825cbb;
  color: white;
}

@media screen and (min-width: 770px) {
  .field_error {
    padding-top: 4px;
    min-height: 27.5;
  }

  .question_input {
    font-size: 24px;
    height: 62px;
  }
}