.businessmatch_label {
  color: rgba(46, 0, 87, 0.38);
  font-weight: normal;
  height: 24px;
  letter-spacing: -0.01px;
  line-height: 16px;
  margin-bottom: 4px;
  display: block;
  word-break: break-word;
  max-width: 428px;
  margin-bottom: 34px;
}
.businessmatch {
  max-width: 428px;
  background: #f6f6fd;
  padding: 17px 21px;
  margin-bottom: 10px;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.2s;
  color: rgba(40, 0, 75, 0.87);
  line-height: 24px;
}
.businessmatch:hover {
  transform: translateX(10px);
}
.businessmatch--selected{
  cursor: initial;
}
.businessmatch--selected:hover{
  transform: none;
}
.businessmatch_head{
  font-weight: 600;
  display:block;
}
.businessmatch_nomatch{
  text-decoration: underline;
  cursor: pointer;
  color: rgba(40, 0, 75, 0.87);
  display: block;
  max-width: 428px;
}
@media screen and (min-width: 770px) {
  .businessmatch_label  {
    padding-top: 8px;
    padding-right: 5px;
  }
}
