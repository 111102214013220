.invoicing {
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    rgba(109, 74, 211, 0.04);
  min-height: 100vh;
  width: 100%;
}

/* Invoicing password modal */
.invoicing-modal {
  padding-top: 60px;
  background: white;
  width: 990px;
  max-width: 100%;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
}

.invoicing-modal_form {
  padding: 0 20px;
}

.invocing-modal-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(109, 74, 211, 0.1);
}

.invoicing-modal_icon {
  position: absolute;
  right: 32px;
  top: 40px;
  border-radius: 10px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding: 0;
}

.invoicing-modal_icon:hover {
  background: #ebe8ed;
  border-radius: 10px;
  transition: all ease-in-out 300ms;
}

.invoicing-modal_head {
  color: #351a4d;
  font-size: 32px;
  font-family: 'Assistant';
  font-weight: 600;
  text-align: center;
  padding-bottom: 72px;
}

/**/

.invoicing_container {
  max-width: 1120px;
  margin: 42px auto 0 auto;
  padding: 0 15px;
}

.invoicing_container--dark {
  opacity: 0.2;
  filter: blur(5px);
  transition: 0.2s;
}

.invoicing_head {
  color: #49315e;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -2.26px;
  font-weight: 800;
  font-family: 'AvenirNext';
  margin: 0;
  padding-top: 120px;
  text-align: center;
}

.invoicing_subhead {
  font-size: 24px;
  color: #725f82;
  margin: 0;
  letter-spacing: -0.5px;
  font-weight: 600;
}

.invoicing_flex {
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
  align-items: flex-start;
}

.invoicing_flex img {
  max-width: 100%;
  height: auto;
}

.invoice_flex__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.content_list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style-type: none;
  padding-inline-start: 0;
  font-family: 'AvenirNext';
}

.invoicing_h2 {
  font-weight: 800;
  font-family: 'AvenirNext';
  color: #8059c8;
  letter-spacing: -2.04px;
  font-size: 32px;
  margin: 0;
  line-height: 100%;
}

.content_list li {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.list_head {
  display: flex;
  gap: 4px;
  align-items: center;
}

.list_head span {
  color: #8059c8;
  font-size: 16px;
  font-weight: 800;
}

.list_desc {
  font-size: 16px;
  font-family: 'Assistant';
  font-weight: 600;
  color: #725f82;
}

.invoice_btn {
  width: fit-content;
  font-size: 16px;
  line-height: 22px;
  height: 60px;
  border-radius: 200px;
}

@media screen and (min-width: 770px) {
  .invoicing-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    border-radius: 16px;
    min-height: 480px;
  }

  .invoicing-modal_icon {
    right: 60px;
  }
}
