.raso-content {
  padding-top: 60px;
  background: white;
  width: 990px;
  max-width: 100%;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
}
.raso-content_form {
  padding: 0 20px;
}
.raso-content_ref{
  background: #E7E3F4;
  color: rgba(41, 0, 77, 0.5);
  transform: scale(0.9);
}
@media screen and (min-width: 770px) {
  .raso-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    border-radius: 16px;
    min-height: 480px;
  }
  .raso {
    width: 100%;
    height: 100vh;
    background: url('./background.jpg') no-repeat;
    background-position: top left;
    background-size: cover;
    position: fixed;
    filter: blur(0px);
    z-index: 10;
  }
}