.check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4caf50;
}

.check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}

.check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

.check-icon::before,
.check-icon::after {
  content: '';
  height: 100px;
  position: absolute;
  background: transparent;
  transform: rotate(-45deg);
}

.icon-line {
  height: 5px;
  background-color: #4caf50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}

.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

.icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  animation: changeColor 0.5s;
  border: 4px solid #4caf50;
}

@keyframes changeColor {
  100% {
    border: 4px solid rgba(76, 175, 80, 0.5);
  }
  0% {
    border: 4px solid rgba(76, 175, 80, 0.5);
  }
}

.icon-fix {
  top: 17px;
  width: 3px;
  left: 26px;
  z-index: 1;
  height: 70px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: transparent;
}

.paid-content {
  display: grid;
  place-items: center;
  padding: 16rem 2rem;
  height: 100vh;
  width: 100vw;
}

.paid-headline {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.top-section-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.67) 0,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      63.37% 115.51% at 40.63% 138.29%,
      #d7b7ff 0,
      rgba(215, 183, 255, 0) 100%
    ),
    radial-gradient(
      56.94% 103.8% at -12.33% 125.63%,
      #f2b6e6 0,
      rgba(242, 182, 230, 0) 100%
    ),
    radial-gradient(
      73.96% 134.81% at 107.81% -57.59%,
      #82a5ff 0,
      rgba(130, 165, 255, 0) 100%
    ),
    linear-gradient(119.96deg, #a33ab1 0, rgba(163, 58, 177, 0) 76.62%), #6d4ad3;
  opacity: 0.12;
  z-index: -1;
}

.success-checkmark {
  width: 90px;
  height: 90px;
  margin: 0 auto;
}
